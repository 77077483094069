import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Card, Grid, TextField, Select, MenuItem, Button, InputLabel, Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { getAttributes, createDog, getDogBySlug, editDog, getAdoptionById, updateAdoption, getFileUrl, getBestMatches } from '../service/actions';

// ----------------------------------------------------------------------

export default function AdoptionPage() {
  const params = new URLSearchParams(window.location.search);
  const [adoption, setAdoption] = useState();
  const [token, setToken] = useState();
  const [newCall, setNewCall] = useState(""); // State for new call input
  const [notes, setNotes] = useState(""); // State for new call input
  const [license, setLicense] = useState("");
  const [licenseUrl, setLicenseUrl] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });


  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]); // Replace with your actual array of dogs
  const [originalResults, setOriginalResults] = useState([]);

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  
    // Implement sorting logic based on the sortConfig
    const sortedDogs = [...results].sort((a, b) => {
      const valueA = getColumnValue(a, column);
      const valueB = getColumnValue(b, column);
  
      if (['totalScore', 'totalTraitsScore', 'weight', 'age'].includes(column)) {
        // For 'totalScore', 'totalTraitsScore', 'weight', and 'age', parse values as numbers
        const numericValueA = parseFloat(valueA);
        const numericValueB = parseFloat(valueB);
  
        return direction === 'asc' ? numericValueA - numericValueB : numericValueB - numericValueA;
      } else {
        // For other columns, use regular sorting logic
        return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
      }
    });
  
    setResults(sortedDogs);
  };

  const getColumnValue = (row, column) => {
    // Implement logic to get the value of the specified column from the row
    switch (column) {
      case 'name':
        return row.dog.name;
      case 'status':
        return row.dog.status;
      case 'transportDistance':
        return row.matchingScore.transportDistance;
      case 'adoptionFee':
        return `$${row.dog.adoptionFee}`;
      case 'gender':
        return row.dog.gender;
      case 'weight':
        return row.dog.weight;
      case 'age':
        return row.dog.age;
      case 'breed':
        return row.dog.breed.name;
      case 'traits':
        return row.dog.personality.map((personality) => personality.name).join(', ');
      case 'commands':
        return row.dog.commands?.map((command) => command.name).join(', ');
      case 'heartworm':
        return row.dog.heartworm;
      case 'totalScore':
        return row.matchingScore.totalScore;
      case 'totalTraitsScore':
        return row.matchingScore.totalTraitScore;
      default:
        return null;
    }
  };
  

  useEffect(() => {
    if (adoption) {
      const userId = adoption.adopter?.id || adoption.foster?.id;

      const fetchBestMatches = async () => {
        try {
          const bestMatchesData = await getBestMatches(userId);
          setResults(bestMatchesData);
          setOriginalResults(bestMatchesData);
        } catch (error) {
          console.error('Error fetching best matches:', error);
          // Handle error as needed
        }
      };

      fetchBestMatches();
    }
  }, [adoption]);

  // Function to handle searching
  const handleSearch = (term) => {
    // If the search term is empty, reset to the original set of results
    if (!term) {
      setResults(originalResults);
      return;
    }
  
    // Implement searching logic based on the searchTerm
    const filteredDogs = originalResults.filter((dog) =>
      dog.dog.name.toLowerCase().includes(term.toLowerCase())
    );
    setResults(filteredDogs);
  };
  

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = localStorage.getItem('token');
    const adoptionId = queryParams.get('adoptionId');
    setToken(token);
    getAdoptionById(adoptionId, token).then(res => {
      setAdoption(res);
      setNotes(res.notes);
    });
  }, []);

  useEffect(() => {
    if (adoption && adoption.adopterLicense && adoption.agreementSigned)
      setLicense(adoption.adopterLicense)
  }, [adoption]);

  useEffect(() => {
    const fetchLicenseUrl = async () => {
      try {

        const fileUrlResult = await getFileUrl(license);


        const fileUrl = fileUrlResult.data.signedUrl;


        setLicenseUrl(fileUrl);
      } catch (error) {
        console.error('Error fetching file URL:', error.message);
      }
    };

    // Check if the license state is not empty before making the request
    if (license) {
      fetchLicenseUrl();
    }
  }, [license]);

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setAdoption((prevAdoption) => ({
      ...prevAdoption,
      status: newStatus.toUpperCase(),
    }));
  };

  const handleTypeChange = (event) => {
    const newStatus = event.target.value;
    setAdoption((prevAdoption) => ({
      ...prevAdoption,
      type: newStatus.toUpperCase(),
    }));
  };

  const handleFosterToAdoptChange = (event) => {
    const newStatus = event.target.value;
    setAdoption((prevAdoption) => ({
      ...prevAdoption,
      interestedInFosterToAdopt: newStatus,
    }));
  };

  const handleSaveStatus = async () => {
    const { foster, dog, ...updatedAdoption } = adoption;
    if (adoption.status === 'APPROVED') {
      const confirmation = window.confirm(`
      To approve this application, please ensure you have entered all required data:
      
      - Transport Contact
      - Transport Information
      - Pickup Address
      - Delivery Fee
      
      Do you wish to proceed?
      `);

      if (!confirmation)
        return;
    }
    try {
      // Make the API request to save the adoption status
      const token = localStorage.getItem('token');
      console.log(notes);
      await updateAdoption(adoption.id, {...updatedAdoption, notes}, token);
      // Display a success message or perform any additional actions
      console.log('Adoption status saved successfully!');
    } catch (error) {
      console.error('Error saving adoption status:', error);
      // Display an error message or handle the error in an appropriate way
    }
  };

  const handleNewCallChange = (event) => {
    setNewCall(event.target.value);
  };

  const handleAddCall = async () => {
    if (newCall.trim() !== "") {
      // Add the new call to the local state
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        calls: [...prevAdoption.calls, newCall],
      }));

      // Prepare the data to be sent to the API
      const updatedAdoptionData = {
        ...adoption,
        calls: [...adoption.calls, newCall], // Include the new call
      };

      try {
        // Make the API request to update the adoption with the new calls
        const token = localStorage.getItem('token');
        await updateAdoption(adoption.id, updatedAdoptionData, token);

        // Display a success message or perform any additional actions
        console.log('Call added and adoption updated successfully!');
      } catch (error) {
        console.error('Error adding call and updating adoption:', error);
        // Display an error message or handle the error in an appropriate way
      }

      setNewCall(""); // Clear the input field
    }
  };

  const handleAddNote = async () => {
    if (notes) {
      const updatedNotes = notes;

      try {
        // Make the API request to update the adoption with the new note
        const token = localStorage.getItem("token");
        await updateAdoption(adoption.id, { ...adoption, notes: updatedNotes }, token);


        // Display a success message or perform any additional actions
        console.log("Note added and adoption updated successfully!");
      } catch (error) {
        console.error("Error adding note and updating adoption:", error);
        // Display an error message or handle the error in an appropriate way
      }
    } else {
      alert('No note to save.')
    }
  };

  return (
    <Card style={{ padding: 50 }}>
      {adoption && (
        <>
          <h1>Application: {adoption.adopter?.name || adoption.foster?.name} {adoption.type === 'FOSTER' ? 'to' : 'for'} {adoption.dog?.name || 'foster'} ({adoption.id}) </h1>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <center>
                <Label style={{ backgroundColor: 'black', color: 'white', fontWeight: 400 }}>Application info</Label>
              </center>
              <br />
              <TextField
  variant="outlined"
  label={adoption.type === 'FOSTER' ? 'Foster Name' : 'Adopter Name'}
  fullWidth
  value={adoption.type === 'FOSTER' ? (adoption.foster ? adoption.foster.name : '') : (adoption.adopter ? adoption.adopter.name : '')}
  onChange={(e) => {
    if (adoption.adopter) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        adopter: { ...prevAdoption.adopter, name: e.target.value },
      }));
    } else if (adoption.foster) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        foster: { ...prevAdoption.foster, name: e.target.value },
      }));
    }
  }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label={adoption.type === 'FOSTER' ? 'Foster Email' : 'Adopter Email'}
  value={adoption.type === 'FOSTER' ? (adoption.foster ? adoption.foster.email : '') : (adoption.adopter ? adoption.adopter.email : '')}
  fullWidth
  onChange={(e) => {
    if (adoption.adopter) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        adopter: { ...prevAdoption.adopter, email: e.target.value },
      }));
    } else if (adoption.foster) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        foster: { ...prevAdoption.foster, email: e.target.value },
      }));
    }
  }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label={adoption.type === 'FOSTER' ? 'Foster Phone' : 'Adopter Phone'}
  value={adoption.type === 'FOSTER' ? (adoption.foster ? adoption.foster.phone : '') : (adoption.adopter ? adoption.adopter.phone : '')}
  fullWidth
  onChange={(e) => {
    if (adoption.adopter) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        adopter: { ...prevAdoption.adopter, phone: e.target.value },
      }));
    } else if (adoption.foster) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        foster: { ...prevAdoption.foster, phone: e.target.value },
      }));
    }
  }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label={adoption.type === 'FOSTER' ? 'Foster Location' : 'Adopter Location'}
  value={adoption.type === 'FOSTER' ? (adoption.foster ? adoption.foster.address : '') : (adoption.adopter ? adoption.adopter.address : '')}
  fullWidth
  onChange={(e) => {
    if (adoption.adopter) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        adopter: { ...prevAdoption.adopter, address: e.target.value },
      }));
    } else if (adoption.foster) {
      setAdoption((prevAdoption) => ({
        ...prevAdoption,
        foster: { ...prevAdoption.foster, address: e.target.value },
      }));
    }
  }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label="Dog Name"
  fullWidth
  value={adoption.dog ? adoption.dog.name : ''}
  InputProps={{ readOnly: true }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label="Dog Age"
  fullWidth
  value={adoption.dog ? adoption.dog.age : ''}
  InputProps={{ readOnly: true }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label="Dog Gender"
  fullWidth
  value={adoption.dog ? adoption.dog.gender : ''}
  InputProps={{ readOnly: true }}
/>
<br />
<br />
<TextField
  variant="outlined"
  label="Dog Breed"
  fullWidth
  value={adoption.dog ? adoption.dog.breedId : ''}
  InputProps={{ readOnly: true }}
/>


              <br />
              <br />
              <Label>Motivation:</Label>
              <p>{adoption.motivation}</p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <center>
                <Label style={{ backgroundColor: 'black', color: 'white', fontWeight: 400 }}>{adoption.type === 'FOSTER' ? 'More Info' : 'Adoption Info'}</Label>
              </center>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Select
                  value={adoption.status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="INCOMPLETE">Incomplete</MenuItem>
                  <MenuItem value="REQUESTED">Requested</MenuItem>
                  <MenuItem value="GHOSTED">Ghosted</MenuItem>
                  <MenuItem value="APPROVED">Approved</MenuItem>
                  <MenuItem value="DENIED">Denied</MenuItem>
                  <MenuItem value="FINISHED">Finished</MenuItem>
                </Select>
              </div>
              
              <br />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Select
                  value={adoption.type}
                  onChange={handleTypeChange}
                >
                  <MenuItem value="FOSTER">Foster</MenuItem>
                  <MenuItem value="ADOPTION">Adoption</MenuItem>

                </Select>
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Select
                  value={adoption.interestedInFosterToAdopt}
                  onChange={handleFosterToAdoptChange}
                >
                  <MenuItem value="Yes">Foster to adopt: Yes</MenuItem>
                  <MenuItem value="No">Foster to adopt: No</MenuItem>

                </Select>
              </div>
              <br />
              <TextField
                value={adoption.transportContact}
                label='Transport Contact'
                fullWidth
                onChange={(e) =>
                  setAdoption((prevAdoption) => ({
                    ...prevAdoption,
                    transportContact: e.target.value,
                  }))
                }
              />
              <br />
              <br />
              <TextField
                value={adoption.transportInformation}
                label='Transport Information'
                fullWidth
                onChange={(e) =>
                  setAdoption((prevAdoption) => ({
                    ...prevAdoption,
                    transportInformation: e.target.value,
                  }))
                }
              />
              <br />
              <br />
              <TextField
                value={adoption.pickupAddress}
                label='Pickup Address'
                fullWidth
                onChange={(e) =>
                  setAdoption((prevAdoption) => ({
                    ...prevAdoption,
                    pickupAddress: e.target.value,
                  }))
                }
              />
              <br />
              <br />
              <TextField
                value={adoption.deliveryFee}
                label='Delivery Fee'
                fullWidth
                onChange={(e) =>
                  setAdoption((prevAdoption) => ({
                    ...prevAdoption,
                    deliveryFee: e.target.value,
                  }))
                }
              />
              <br />
              <br />
              <Label>Notes:</Label>
              <div style={{ width: '100% ' }}>
                <textarea
                  rows="10"
                  value={notes}
                  style={{ width: '100% ' }}
                  onChange={(e) =>
                    setNotes(e.target.value)
                  }
                  cols='70'
                /><br />
                {`${(notes || '').length}/800`}

                <br />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {adoption.calls.map((call, index) => (
                  <p key={index}>{call}</p>
                ))}
                <TextField
                  value={newCall}
                  onChange={handleNewCallChange}
                  placeholder="Add a call..."
                />

                <br />
                <Button onClick={handleAddCall}>Add</Button>
              </div>

              {/* Add other form fields for the second column */}
            </Grid>
            {/* Continue adding other Grid items for the form fields */}
          </Grid>
          <br />
          <br />
          <Button onClick={handleSaveStatus}>Save</Button>
          <Divider textAlign="center">Best Matches</Divider>
          <br />
          <TextField
            label="Search by name"
            fullWidth
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value);
            }}
          />
          <br />
          <br />
          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pic</TableCell>
                  <TableCell onClick={() => handleSort('name')}>Name</TableCell>
                  <TableCell onClick={() => handleSort('status')}>Status</TableCell>
                  <TableCell onClick={() => handleSort('transportDistance')}>Transport Distance</TableCell>
                  <TableCell onClick={() => handleSort('adoptionFee')}>Adoption Fee</TableCell>
                  <TableCell onClick={() => handleSort('gender')}>Gender</TableCell>
                  <TableCell onClick={() => handleSort('weight')}>Weight (Lbs.)</TableCell>
                  <TableCell onClick={() => handleSort('age')}>Age</TableCell>
                  <TableCell onClick={() => handleSort('breed')}>Breed</TableCell>
                  <TableCell onClick={() => handleSort('traits')}>Traits</TableCell>
                  <TableCell onClick={() => handleSort('commands')}>Commands</TableCell>
                  <TableCell onClick={() => handleSort('heartworm')}>Heartworm</TableCell>
                  <TableCell onClick={() => handleSort('totalScore')}>Total Score</TableCell>
                  <TableCell onClick={() => handleSort('totalTraitsScore')}>Total Traits Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={result.dog.id}>
                    <TableCell>
                      <img
                        src={`https://cdn.petpair.co/50x50/${result.dog.photos[0]}`}
                        alt={result.dog.name}
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%', // Make the image circular
                          objectFit: 'cover', // Ensure the image covers the circular space
                          marginRight: '75px', // Adjust spacing between image and text
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <a href={`https://petpair.co/dog/${result.dog.slug}`} style={{textDecoration: 'underline', color: 'black', marginRight: 100}}>{result.dog.name}</a><br/>
                      <span style={{color: '#919EAB'}}>{result.dog.shelter.city}</span><br/>
                      <span style={{color: '#919EAB'}}>{result.dog.shelter.name}</span><br />
                    </TableCell>
                    <TableCell>{result.dog.status}</TableCell>
                    <TableCell>{result.matchingScore.transportDistance}</TableCell>
                    <TableCell>${result.dog.adoptionFee}</TableCell>
                    <TableCell>{result.dog.gender}</TableCell>
                    <TableCell>{result.dog.weight}</TableCell>
                    <TableCell>{result.dog.age}</TableCell>
                    <TableCell>{result.dog.breed.name}</TableCell>
                    <TableCell>
                      {result.dog.personality.map((personality) => personality.name).join(', ')}
                    </TableCell>
                    <TableCell>
                      {result.dog.commands?.map((command) => command.name).join(', ')}
                    </TableCell>
                    <TableCell>
                      {result.dog.heartworm}
                    </TableCell>
                    <TableCell>
                    {result.matchingScore?.totalScore?.toFixed(1)}%
                    </TableCell>
                    <TableCell>
                    {result.matchingScore?.totalTraitScore?.toFixed(1)}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );


}
