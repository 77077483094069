import axios from 'axios';


const api = axios.create({
    baseURL: 'https://api.petpair.co/',
    headers: {
        'Access-Control-Allow-Origin': '*',
    }
});

export default api;