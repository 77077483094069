import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';


import Label from './components/label';
import Iconify from './components/iconify';
import { format } from 'date-fns';
import { useIsFocusVisible } from '@mui/material';
import CustomPopover, { usePopover } from './components/custom-popover';



// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, onCompleteTask, onUndoComplete, onCancelTask, onSelectRow, onUpdateDueDate, onUpdateAssignee }) {
  const { name, assignee, type, application, dueDate, status, id } = row;

  const [dueDateModalOpen, setDueDateModalOpen] = useState(false);
  const [newDueDate, setNewDueDate] = useState('');
  const [newDueDateISOString, setNewDateISOString] = useState('');

  const formatDueDate = (dateString) => {
    if (!dateString) {
      // Return a default date or an empty string based on your requirements
      return 'N/A'; // You can adjust this to fit your needs
    }

    // Check for ISO 8601 format (e.g., 'YYYY-MM-DDTHH:mm:ss.sssZ')
    const isoParts = dateString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/);

    if (isoParts) {
      const [, year, month, day, hour, minute, second, millisecond] = isoParts.map(Number);
      const dueDateObject = new Date(Date.UTC(year, month - 1, day, hour, minute, second, millisecond));
      return dueDateObject.toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

    // Check for 'YYYY-MM-DD' format
    const simpleParts = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);

    if (simpleParts) {
      const [, year, month, day] = simpleParts.map(Number);
      const dueDateObject = new Date(Date.UTC(year, month - 1, day)); // Note: Months are 0-based
      return dueDateObject.toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

    // Return a default date or an empty string for unsupported formats
    return 'Invalid Date'; // You can adjust this to fit your needs
  };

  const formattedDueDate = formatDueDate(dueDate || newDueDateISOString);




  const isLineThrough = status === 'completed' || status === 'cancelled' ? 'line-through' : 'none';

  const popover = usePopover();

  function getDueStatusText(dueDate) {
    const today = new Date();
    const formattedDueDate = new Date(dueDate);

    if (formattedDueDate.toDateString() === today.toDateString()) {
      return 'Due today!';
    } else if (formattedDueDate < today) {
      const daysOverdue = Math.ceil((today - formattedDueDate) / (1000 * 60 * 60 * 24));
      return `${daysOverdue} days overdue`;
    } else {
      return ''; // Task is not overdue and not due today
    }
  }

  const [assigneeModalOpen, setAssigneeModalOpen] = useState(false);
  const [newAssigneeId, setNewAssigneeId] = useState('');
  const assigneeOptions = [
    { id: '66db2d92-75d2-438f-ae32-97d3615230f9', name: 'Christie' },
    { id: '32ed94e4-511a-48a4-a4de-37eb8abe8b11', name: 'Yanett' },
  ];

  const handleOpenAssigneeModal = () => {
    setAssigneeModalOpen(true);
  };

  const handleCloseAssigneeModal = () => {
    setAssigneeModalOpen(false);
  };

  const handleAssigneeUpdate = async () => {
    onUpdateAssignee(id, newAssigneeId);
    setAssigneeModalOpen(false);
  };

  const handleOpenDueDateModal = () => {
    setDueDateModalOpen(true);
  };

  const handleCloseDueDateModal = () => {
    setDueDateModalOpen(false);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={assignee.name} src={assignee.name} sx={{ mr: 2 }} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}><span style={{ textDecoration: isLineThrough }}>{name}</span><br /><span style={{ color: '#919EAB', textDecoration: isLineThrough }}>{type === 'email' ? application.adopter.email : application.adopter.phone}</span></TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <span style={{ textDecoration: isLineThrough }}>{formattedDueDate}</span><br />
          <span style={{ color: '#FF5630', fontSize: '12px' }}>{getDueStatusText(dueDate)}</span>
        </TableCell>


        <TableCell sx={{ whiteSpace: 'nowrap' }} color='success'><a style={{ color: '#00A76F', textDecoration: isLineThrough }} href={`#/dashboard/dog/new?slug=${application.dog.slug}`}>{application.dog.name}</a></TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }} color='success'><a style={{ color: '#00A76F', textDecoration: isLineThrough }} href={`#/dashboard/adoption/details?adoptionId=${application.id}`}>{application.id}</a></TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (status === 'active' && 'success') ||
              (status === 'pending' && 'warning') ||
              (status === 'cancelled' && 'error') ||
              'success'
            }
          >
            <span style={{ textDecoration: status === 'completed' || status === 'cancelled' ? 'line-through' : 'none' }}>{type}</span>
          </Label>
        </TableCell>

        <TableCell align="left" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title={status === 'completed' || status === 'cancelled' ? "Undo" : "Complete task"} placement="top" arrow onClick={() => {
            if(status === 'completed' || status === 'cancelled')
              onUndoComplete(id);
            else
              onCompleteTask(id);
          }}>
            <IconButton>
              <Iconify icon={status === 'completed' || status === 'cancelled' ? "solar:reply-outline" : "carbon:checkmark-filled"} />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onCancelTask(id)
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="foundation:x" />
          Cancel
        </MenuItem>

        <MenuItem
          onClick={() => {
            setDueDateModalOpen(true);
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Due date
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenAssigneeModal();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Assignee
        </MenuItem>
      </CustomPopover>

      <Dialog open={dueDateModalOpen} onClose={handleCloseDueDateModal}>
        <DialogTitle>Enter New Due Date</DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            label=""
            value={newDueDate}
            onChange={(e) => {const selectedDate = e.target.value;

              // Assuming selectedDate is in the format 'YYYY-MM-DD'
              const localDate = new Date(selectedDate + 'T00:00:00');
              setNewDateISOString(localDate);
              const localDateString = localDate.toISOString().split('T')[0];
          
              setNewDueDate(localDateString);}}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDueDateModal}>Cancel</Button>
          <Button onClick={() => {onUpdateDueDate(id, newDueDate); handleCloseDueDateModal();}}>Update Due Date</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={assigneeModalOpen} onClose={handleCloseAssigneeModal}>
        <DialogTitle>Assign New Assignee</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              value={newAssigneeId}
              onChange={(e) => setNewAssigneeId(e.target.value)}
            >
              {assigneeOptions.map((assignee) => (
                <MenuItem key={assignee.id} value={assignee.id}>
                  {assignee.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssigneeModal}>Cancel</Button>
          <Button onClick={handleAssigneeUpdate}>Update Assignee</Button>
        </DialogActions>
      </Dialog>


    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
