'use client';

import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';


import {
  Stack,
  Typography
} from '@mui/material';


import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';

import { getTasks, completeTask, undoCompleteTask, cancelTask, updateDueDate, updateAssignee } from '../service/actions';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, { value: 'Christie', label: 'Christie' }, { value: 'Yanett', label: 'Yanett' } ];

const TABLE_HEAD = [
  { id: 'assignee', label: 'Who?' },
  { id: 'task', label: 'Task' },
  { id: 'dueDate', label: 'Due Date'},
  { id: 'dog', label: 'Dog'},
  { id: 'application', label: 'Application', width: 88},
  { id: 'type', label: 'Type' },
  { id: 'actions', label: 'Actions' },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function UserListView() {
  const table = useTable();




  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const result = await getTasks();
        console.log(result)
        setTasks(result);
        setFilteredTasks(result);

      } catch (error) {
        console.error(error);
      }
    };

    fetchTasks();
  }, []);

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      if(name === 'status')
        filterSelectedAssignee(value);
      if(name === 'name')
        debouncedFilterTasks(value);
    },
    [table]
  );

  const debouncedFilterTasks = (term) => {
    console.log(term);
    setTimeout(() => {
      const filtered = tasks.filter((task) => {
        const lowerCaseTerm = term.toLowerCase();
        // Customize the conditions based on your needs
        return (
          task.name.toLowerCase().includes(lowerCaseTerm) ||
          task.application?.adopter.name?.toLowerCase().includes(lowerCaseTerm) ||
          task.type.toLowerCase().includes(lowerCaseTerm)
        );
      });
      console.log(filtered);
      setFilteredTasks(filtered);
    }, 300);
  };

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);



  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
    setFilteredTasks(tasks);
  }, []);

  const filterSelectedAssignee = (value) => {
    // Filter tasks based on assignee.email
    const filteredTasks = tasks.filter(task => {
      if (value === 'Christie') {
        return task.assignee?.email === 'christie@petpair.co';
      } else if (value === 'Yanett') {
        return task.assignee?.email === 'yanett@petpair.co';
      } else {
        // Add conditions for other values if needed
        return true;
      }
    });
  
    // Update state variable with filtered 
    setFilteredTasks(filteredTasks);
  }

  const handleCompleteTask = async (taskId) => {
    try {
      const result = await completeTask(taskId);

      // Update the tasks array
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'completed' } : task
        )
      );

      // Update the filteredTasks array
      setFilteredTasks((prevFilteredTasks) =>
        prevFilteredTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'completed' } : task
        )
      );

      console.log('Task completed:', result);
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleUndoCompleteTask = async (taskId) => {
    try {
      const result = await undoCompleteTask(taskId);

      // Update the tasks array
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'waiting' } : task
        )
      );

      // Update the filteredTasks array
      setFilteredTasks((prevFilteredTasks) =>
        prevFilteredTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'waiting' } : task
        )
      );

      console.log('Task completion undone:', result);
    } catch (error) {
      console.error('Error undoing completion of task:', error);
    }
  };

  const handleUpdateDueDate = async (taskId, newDueDate) => {
    try {
      const result = await updateDueDate(taskId, newDueDate);
  
      // Update the tasks and filteredTasks arrays
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, dueDate: newDueDate } : task
        )
      );
  
      setFilteredTasks((prevFilteredTasks) =>
        prevFilteredTasks.map((task) =>
          task.id === taskId ? { ...task, dueDate: newDueDate } : task
        )
      );
  
      console.log('Due date updated:', result);
    } catch (error) {
      console.error('Error updating due date:', error);
    }
  };
  
  const handleUpdateAssignee = async (taskId, newAssigneeId) => {
    try {
      const result = await updateAssignee(taskId, newAssigneeId);

      console.log(result);
  
      // Update the tasks and filteredTasks arrays
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, assigneeId: newAssigneeId, assignee: {...result.assignee} } : task
        )
      );
  
      setFilteredTasks((prevFilteredTasks) =>
        prevFilteredTasks.map((task) =>
          task.id === taskId ? { ...task, assigneeId: newAssigneeId, assignee: {...result.assignee} } : task
        )
      );
  
      console.log('Assignee updated:', result);
    } catch (error) {
      console.error('Error updating assignee:', error);
    }
  };

  const handleCancelTask = async (taskId) => {
    try {
      const result = await cancelTask(taskId);

      // Update the tasks array
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'cancelled' } : task
        )
      );

      // Update the filteredTasks array
      setFilteredTasks((prevFilteredTasks) =>
        prevFilteredTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'cancelled' } : task
        )
      );

      console.log('Task canceled:', result);
    } catch (error) {
      console.error('Error canceling task:', error);
    }
  };

  



  return (
    <>
      <Container maxWidth={'xl'}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Task
          </Typography>
        </Stack>

        <Card>
          <Tabs
            value={filters.status}
            onChange={(e, v) => {
              handleFilterStatus(e, v);
              filterSelectedAssignee(v);
            }}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => {
  // Filter tasks based on assignee.email
  const filteredTasks = tasks.filter(task => {
    if (tab.label === 'Christie') {
      return task.assignee?.email === 'christie@petpair.co';
    } else if (tab.label === 'Yanett') {
      return task.assignee?.email === 'yanett@petpair.co';
    } else {
      // Add conditions for other tabs if needed
      return true;
    }
  });

  return (
    <Tab
      key={tab.value}
      iconPosition="end"
      value={tab.value}
      label={tab.label}
      icon={
        <Label
          variant={
            ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
          }
          color={
            (tab.value === 'active' && 'success') ||
            (tab.value === 'pending' && 'warning') ||
            (tab.value === 'banned' && 'error') ||
            'default'
          }
        >
          {filteredTasks.length}
        </Label>
      }
    />
  );
})}
          </Tabs>

          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            roleOptions={[]}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {filteredTasks
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        onCompleteTask={handleCompleteTask}
                        onUndoComplete={handleUndoCompleteTask}
                        onUpdateDueDate={handleUpdateDueDate}
                        onUpdateAssignee={handleUpdateAssignee}
                        onCancelTask={handleCancelTask}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}


                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tasks.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>


    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}