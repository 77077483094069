import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { login } from '../../../service/actions';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state to track loading state

  const handleClick = async () => {
    try {
      setIsLoading(true); // Set loading state to true

      const result = await login(email, password);
      const token = result.data.token;
      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(result.data.user));
        navigate('/dashboard/user')
      }      
    } catch (error) {
      if (error.message === 'Wrong password') {
        console.error('Incorrect password');
      } else {
        console.error(error);
      }
      localStorage.removeItem('token');
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />

      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        onClick={handleClick}
        loading={isLoading} // Set the loading state of the button
        disabled={isLoading} // Disable the button while loading
      >
        Login
      </LoadingButton>
    </>
  );
}
