import api from './api';

export const login = async (email, password) => {
  let result;
  try {
    result = await api.post('/admin/login', { email, password });
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export const getBestMatches = async (userId) => {
  try {
    const result = await api.get(`/best-matches/${userId}`);
    return result.data;
  } catch (error) {
    console.error('Error fetching best matches:', error);
    throw error;
  }
};

export const getUsers = async (token) => {
  let result;
  try {
    result = await api.get('/users', {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getFileUrl = async (fileName) => {
  try {
    const result = await api.get(`/file-url?fileName=${fileName}`);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUploadUrlForDogImage = async (fileName) => {
  try {
    const response = await api.get('/upload-url-dog-image', {
      params: {
        fileName,
      },
    });

    return response.data.signedUrl;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const completeTask = async (taskId) => {
  try {
    const response = await api.put(`/tasks/${taskId}/complete`);
    return response.data;
  } catch (error) {
    console.error('Error completing task:', error);
    throw error;
  }
};

// Undo completion of a task by taskId
export const undoCompleteTask = async (taskId) => {
  try {
    const response = await api.put(`/tasks/${taskId}/undo-complete`);
    return response.data;
  } catch (error) {
    console.error('Error undoing completion of task:', error);
    throw error;
  }
};

export const updateDueDate = async (taskId, dueDate) => {
  try {
    const response = await api.put(`/tasks/${taskId}/update-due-date`, { dueDate });
    return response.data;
  } catch (error) {
    console.error('Error updating due date:', error);
    throw error;
  }
};

// Update assignee for a task by taskId
export const updateAssignee = async (taskId, assigneeId) => {
  try {
    const response = await api.put(`/tasks/${taskId}/update-assignee`, { assigneeId });
    return response.data;
  } catch (error) {
    console.error('Error updating assignee:', error);
    throw error;
  }
};

// Cancel a task by taskId
export const cancelTask = async (taskId) => {
  try {
    const response = await api.put(`/tasks/${taskId}/cancel`);
    return response.data;
  } catch (error) {
    console.error('Error canceling task:', error);
    throw error;
  }
};


export const getAdoptions = async (page, token) => {
  try {

    const result = await api.get('/adoption', {
      params: {
        page: page.toString(),
      },
      headers: {
        Authorization: token,
      }
    });

    return result.data.adoptionList;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getTasks = async () => {
  try {

    const result = await api.get('/tasks');

    return result.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getAdoptionById = async (id, token) => {
  try {

    const result = await api.get(`/adoption/${id}`, {
      headers: {
        Authorization: token,
      }
    });

    return result.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getDogs = async () => {
  let result;
  try {
    result = await api.get('/dogs-admin');
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getAttributes = async () => {
  try {
    const result = await api.get('/dog-attributes');
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const createDog = async (dogData, token) => {
  try {
    const result = await api.post('/dogs', dogData, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const editDog = async (dogData, id, token) => {
  try {
    const result = await api.put(`/dogs/${id}`, dogData, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const updateAdoption = async (id, adoption, token) => {
  try {
    const result = await api.put(`/adoption/${id}`, adoption, {
      headers: {
        Authorization: token,
      },
    });
    alert('Changes saved');
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getDogBySlug = async (slug) => {
  let result;
  try {
    result = await api.get('/dog', { params: { slug } });
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export const deleteDog = async (id, token) => {
  try {
    const result = await api.delete(`/dogs/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    console.error(err);
    return err;
  }
};
