import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import TaskPage from './pages/UserListPage';
import UserPage from './pages/UserPage';
import ApplicationsPage from './pages/ApplicationsPage';
import DogPage from './pages/DogPage';
import NewDog from './pages/NewDog';

import AdoptionPage from './pages/AdoptionPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('token');

  const routes = useRoutes([
    {
      path: '',
      element: <LoginPage />,
      index: true
    },
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" replace />,
      children: [
        { element: <Navigate to="/dashboard/app" /> },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <TaskPage /> },
        { path: 'user-list', element: <UserPage /> },
        { path: 'applications', element: <ApplicationsPage /> },
        { path: 'dogs', element: <DogPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'dog/new', element: <NewDog /> },
        { path: 'adoption/details', element: <AdoptionPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
