import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Card, Grid, TextField, Select, MenuItem, Button, InputLabel } from '@mui/material';
// components
import { v4 as uuidv4 } from 'uuid';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { getAttributes, createDog, getDogBySlug, editDog, getUploadUrlForDogImage } from '../service/actions';

// ----------------------------------------------------------------------

export default function DogPage() {
  const params = new URLSearchParams(window.location.search);
  const slug = params.get('slug');
  const [dogStatus, setDogStatus] = useState('IN_SHELTER');
  const [gender, setGender] = useState('MALE');
  const [traits, setTraits] = useState([]);
  const [commands, setCommands] = useState([]);
  const [behaviors, setBehaviors] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [breed, setBreed] = useState('');
  const [sleepStyle, setSleepStyle] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [dogSlug, setDogSlug] = useState('');
  const [weight, setWeight] = useState('');
  const [persona, setPersona] = useState('');
  const [fosterId, setFosterId] = useState('');
  const [shelterId, setShelterId] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [sleepStyles, setSleepStyles] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [token, setToken] = useState('');
  const [adoptionFee, setAdoptionFee] = useState('');
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState([]);
  const [selectedTrainingTypes, setSelectedTrainingTypes] = useState([]);
  const [selectedBehaviors, setSelectedBehaviors] = useState([]);
  const [fixed, setFixed] = useState('YES');
  const [dogId, setDogId] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const photoInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const convertToWebP = (file, quality = 0.8) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
  
          // Convert the canvas to a WebP blob with the specified quality
          canvas.toBlob(
            (blob) => resolve(blob),
            'image/webp',
            quality
          );
        };
        img.onerror = reject;
      };
      reader.readAsDataURL(file);
    });
  };

  const handlePhotoUpload = async () => {
    const fileInput = photoInputRef.current;
    const file = fileInput.files[0];

    if (file) {
      try {
        // Convert the image to WebP format
        const webpBlob = await convertToWebP(file, 0.8);

        // Get a signed URL for the file with a random name (e.g., using UUID)
        const fileName = `${uuidv4()}.webp`;
        const signedUrl = await getUploadUrlForDogImage(fileName);

        // Upload the WebP blob to the signed URL using Axios
        const response = await axios.put(signedUrl, webpBlob, {
          headers: {
            'Content-Type': 'image/webp', // Set the Content-Type header to WebP
          },
        });

        if (response.status === 200) {
          // Add the file name to the photos array
          setPhotos([...photos, fileName]);

          // Clear the file input
          fileInput.value = '';
        } else {
          console.error('Error uploading photo. Response status:', response.status);
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }
  };

  // Function to handle file upload for videos (similar to photos)
  const handleVideoUpload = async () => {
    const fileInput = videoInputRef.current;
    const file = fileInput.files[0];

    if (file) {
      try {
        const extension = file.name.split('.').pop(); // Get the original file extension
        const fileName = `${uuidv4()}.${extension}`;

        const signedUrl = await getUploadUrlForDogImage(fileName);

        // Upload the file content to the signed URL using Axios
        const response = await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type, // Set the Content-Type header
          },
        });

        if (response.status === 200) {
          // Add the file name to the videos array
          setVideos([...videos, fileName]);

          // Clear the file input
          fileInput.value = '';
        } else {
          console.error('Error uploading video. Response status:', response.status);
        }
      } catch (error) {
        console.error('Error uploading video:', error);
      }
    }
  };
 
  useEffect(() => {
    // Fetching attributes data

    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get('slug');


    console.log(slug);
    const fetchData = async () => {
      try {
        const attributes = await getAttributes();
        setTraits(attributes.data.traits);
        setCommands(attributes.data.commands);
        setBehaviors(attributes.data.behaviors);
        setTrainingTypes(attributes.data.trainingTypes);
        setSleepStyles(attributes.data.sleepStyles);
        setBreeds(attributes.data.breeds);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDogBySlug = async () => {
        try {
          const result = await getDogBySlug(slug);
          // Populate the state with the fetched dog data
          const dogData = result.data; // Modify this based on your API response structure
          setGender(dogData.gender);
          setSelectedTraits(dogData.personality.map(trait => trait.id));
          setSelectedCommands(dogData.commands.map(command => command.id));
          setSelectedBehaviors(dogData.behaviors.map(behavior => behavior.id));
          setSelectedTrainingTypes(dogData.trainedFor.map(training => training.id));
          setBreed(dogData.breedId);
          setSleepStyle(dogData.sleepStyleId);
          setDogSlug(dogData.slug);
          setName(dogData.name);
          setAge(dogData.age);
          setWeight(dogData.weight);
          setPersona(dogData.persona);
          setFosterId(dogData.fosterId);
          setShelterId(dogData.shelterId);
          setAddress(dogData.address);
          setZipCode(dogData.zipCode);
          setDescription(dogData.description);
          setFixed(dogData.fixed ? 'YES' : 'NO');
          setPhotos(dogData.photos);
          setVideos(dogData.videos);
          setDogId(dogData.id);
          setAdoptionFee(dogData.adoptionFee);
          setDogStatus(dogData.status);
        } catch (error) {
          console.error('Error fetching dog data:', error);
        }
      };

    if(slug) 
      fetchDogBySlug();

    fetchData();

    setToken(localStorage.getItem('token'));
  }, []);

  const handleSubmit = async () => {
    // Splitting photos and videos string into arrays

  
    // Creating the dog object with all the form data
    const dog = {
      gender,
      personality: selectedTraits,
      commands: selectedCommands,
      behaviors: selectedBehaviors,
      trainedFor: selectedTrainingTypes,
      breedId: breed,
      sleepStyleId: sleepStyle,
      name,
      age,
      weight,
      slug: dogSlug,
      persona,
      fosterId,
      shelterId,
      adoptionFee: parseFloat(adoptionFee),
      address,
      zipCode,
      description,
      fixed: fixed === 'YES',
      photos,
      videos,
      status: dogStatus
    };

    try {
      let result;
    if(dogId) {
        result = await editDog(dog, dogId, token);
        navigate('/dashboard/dogs');
    }
    else {
      result = await createDog(dog, token);
      console.log('Dog created:', result);
    }
    } catch (error) {
      console.error('Error creating dog:', error);
    }
  };

  const handleAddPhoto = () => {
    setPhotos([...photos, '']);
  };

  const handleRemovePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };

  const handleChangePhoto = (index, value) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index] = value;
    setPhotos(updatedPhotos);
  };

  const handleAddVideo = () => {
    setVideos([...videos, '']);
  };
  
  const handleRemoveVideo = (index) => {
    const updatedVideos = [...videos];
    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
  };

  const handleChangeVideo = (index, value) => {
    const updatedVideos = [...videos];
    updatedVideos[index] = value;
    setVideos(updatedVideos);
  };

  return (
    <Card style={{ padding: 50 }}>
      <h1>Dog</h1>
      <Grid columns={2} container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="slug"
            label="Slug"
            variant="outlined"
            fullWidth
            value={dogSlug}
            onChange={(e) => setDogSlug(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="age"
            label="Age"
            variant="outlined"
            fullWidth
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="weight"
            label="Weight"
            variant="outlined"
            fullWidth
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="adoptionFee"
            label="Adoption Fee ($)"
            variant="outlined"
            fullWidth
            value={adoptionFee}
            onChange={(e) => setAdoptionFee(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="persona"
            label="Persona"
            variant="outlined"
            fullWidth
            value={persona}
            onChange={(e) => setPersona(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="fosterId"
            label="Foster ID"
            variant="outlined"
            fullWidth
            value={fosterId}
            onChange={(e) => setFosterId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="shelterId"
            label="Shelter ID"
            variant="outlined"
            fullWidth
            value={shelterId}
            onChange={(e) => setShelterId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="address"
            label="Address"
            variant="outlined"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="zipCode"
            label="Zipcode"
            variant="outlined"
            fullWidth
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            value={gender}
            label="Gender"
            fullWidth
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value={'MALE'}>Male</MenuItem>
            <MenuItem value={'FEMALE'}>Female</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="fixed-label">Fixed</InputLabel>
          <Select
            labelId="fixed-label"
            id="fixed"
            value={fixed}
            label="Fixed"
            fullWidth
            onChange={(e) => setFixed(e.target.value)}
          >
            <MenuItem value={'YES'}>Yes</MenuItem>
            <MenuItem value={'NO'}>No</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="breed-label">Breed</InputLabel>
          <Select
            labelId="sleepStyle-label"
            id="breed"
            value={breed}
            label="Breed"
            fullWidth
            onChange={(e) => setBreed(e.target.value)}
          >
            {breeds.map((breed) => (
              <MenuItem key={breed.id} value={breed.id}>
                {sentenceCase(breed.name)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="sleepStyle-label">Sleep Style</InputLabel>
          <Select
            labelId="sleepStyle-label"
            id="sleepStyle"
            value={sleepStyle}
            label="Sleep Style"
            fullWidth
            onChange={(e) => setSleepStyle(e.target.value)}
          >
            {sleepStyles.map((style) => (
              <MenuItem key={style.id} value={style.id}>
                {sentenceCase(style.name)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="traits-label">Traits</InputLabel>
          <Select
            multiple
            labelId="traits-label"
            id="traits"
            value={selectedTraits}
            label="Traits"
            fullWidth
            onChange={(e) => setSelectedTraits(e.target.value)}
          >
            {traits.map((trait) => (
              <MenuItem key={trait.id} value={trait.id}>
                {trait.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="commands-label">Commands</InputLabel>
          <Select
            multiple
            labelId="commands-label"
            id="commands"
            value={selectedCommands}
            label="Commands"
            fullWidth
            onChange={(e) => setSelectedCommands(e.target.value)}
          >
            {commands.map((command) => (
              <MenuItem key={command.id} value={command.id}>
                {command.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="behaviors-label">Behaviors</InputLabel>
          <Select
            multiple
            labelId="behaviors-label"
            id="behaviors"
            value={selectedBehaviors}
            label="Behaviors"
            fullWidth
            onChange={(e) => setSelectedBehaviors(e.target.value)}
          >
            {behaviors.map((behavior) => (
              <MenuItem key={behavior.id} value={behavior.id}>
                {behavior.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel id="trainingTypes-label">Training Types</InputLabel>
          <Select
            multiple
            labelId="trainingTypes-label"
            id="trainingTypes"
            value={selectedTrainingTypes}
            label="Training Types"
            fullWidth
            onChange={(e) => setSelectedTrainingTypes(e.target.value)}
          >
            {trainingTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {`${(description || '').length}/1400`}
        </Grid>


        <Grid item xs={12} sm={6} md={4}>
        <input
          type="file"
          ref={photoInputRef}
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handlePhotoUpload}
        />
        <Button onClick={() => photoInputRef.current.click()}>Upload Photo</Button>
      </Grid>

      {photos.map((photo, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <TextField
            label={`Photo ${index + 1}`}
            variant="outlined"
            fullWidth
            value={photo}
            onChange={(e) => handleChangePhoto(index, e.target.value)}
          />
          
          { photos.length > 1 &&
            <Button onClick={() => handleRemovePhoto(index)}>Remove</Button>
          }
        </Grid>
      ))}
      {/* <Grid item xs={12} sm={6} md={4}>
            <Button onClick={handleAddPhoto}>Add Photo</Button>
      </Grid> */}

      <Grid item xs={12} sm={6} md={4}>
        <input
          type="file"
          ref={videoInputRef}
          accept="video/*"
          style={{ display: 'none' }}
          onChange={handleVideoUpload}
        />
        <Button onClick={() => videoInputRef.current.click()}>Upload Video</Button>
      </Grid>
    
      {videos.map((video, index) => (
  <Grid item xs={12} sm={6} md={4} key={index}>
    <TextField
      label={`Video ${index + 1}`}
      variant="outlined"
      fullWidth
      value={video}
      onChange={(e) => handleChangeVideo(index, e.target.value)}
    />
    {videos.length > 0 && (
      <Button onClick={() => handleRemoveVideo(index)}>Remove</Button>
    )}
  </Grid>
))}
{/* <Grid item xs={12} sm={6} md={4}>
  <Button onClick={handleAddVideo}>Add Video</Button>
</Grid> */}
<Grid item xs={12} sm={6} md={4}>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            value={dogStatus}
            label="Status"
            fullWidth
            onChange={(e) => setDogStatus(e.target.value)}
          >
            <MenuItem value="IN_SHELTER">In Shelter</MenuItem>
            <MenuItem value="WAITING_FOR_TRANSIT">Waiting for Transit</MenuItem>
            <MenuItem value="IN_TRANSIT">In Transit</MenuItem>
            <MenuItem value="IN_HOME">In Home</MenuItem>
            <MenuItem value="ADOPTED">Adopted</MenuItem>
            <MenuItem value="UNAVAILABLE">Unavailable</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

